<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">기간 설정</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    v-model="vacationDateRange"
                    type="lookup-condition"
                />
              </li>
            </ul>
          </li>

        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="onViewCaddieInfo"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">과세자료제출명세서 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">

                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"> Excel </erp-button>
                </li>
              </ul>

            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
                ref="caddieInfoGrid"
                :provides="caddieInfoGridOptions.provides"
                :columns="caddieInfoGridOptions.columns"
                :dataSource="caddieVacationList"
                :allowExcelExport="true"
                :allowResizing="true"
                :selectionSettings="selectionSettings"
                :pageSettings="caddieInfoGridOptions.pageSettings"
                :editSettings="caddieInfoGridOptions.editSettings"
                :validationRules="caddieInfoGridOptions.validationRules"
                :allowPaging="true"
                :allowFiltering="!isModifyMode"
                :allowSorting="!isModifyMode"
                :isAutoSelectCell="!isModifyMode"
                :isCustomizeKeyboardAction="true"
                @actionComplete="caddieInfoGridActionComplete"
                @cellSaved="onCaddieInfoGridCellSaved"
                @cellSelected="onCaddieInfoGridCellSelected"
                @excelExportComplete="onCaddieInfoGridExcelExportComplete"
            />
          </div>
        </section>
      </article>
    </div>

  </div>
</template>

<script>
import moment from "moment";
import { getFormattedDate } from "@/utils/date";
import { commonCodesGetComName } from "@/utils/commonCodes";
import { isGridModified } from "@/utils/gridUtil";
import { numberWithCommas } from "@/utils/number";
import {
  Edit,
  ForeignKey,
  Selection,
  Page,
  ExcelExport, Resize,
} from "@syncfusion/ej2-vue-grids";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import ErpButton from "@/components/button/ErpButton.vue";
import GolfErpAPI from '@/api/v2/GolfErpAPI';

export default {
  name: "SubmitTaxData",
  components: {
    InputDateRange,
    EjsGridWrapper,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  created() {
    this.searchConditions.vacationStartDate = getFormattedDate(new Date(new Date().getFullYear(),new Date().getMonth(), 1));
    this.searchConditions.vacationEndDate = getFormattedDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    this.searchConditions.vacationStartDayOfTheWeek = commonCodesGetComName(
        "DW_CODE",
        String(new Date().getDay() + 1)
    );
    this.searchConditions.vacationEndDayOfTheWeek = commonCodesGetComName(
        "DW_CODE",
        String(new Date().getDay() + 1)
    );


  },
  data() {
    return {
      excelName:"과세자료제출명세서.xlsx",
      count: 0,
      caddieVacationListLength: 0,
      caddieVacationList: [],
      isCaddieSelectPopupOpen: false,
      isEditMultipleColumnsPopupOpen: false,
      field: null, //textField: 전체 검색 창에서 검색 , gridField: 그리드 내 row 개별 검색
      addedRecordList: [], //grid 내에서 동명이인 팝엄 검색전 save된 row list
      searchConditions: {
        //textField
        caddieId: null, //textField에서 검색 시 caddieId가 존재하는 경우 caddieName 아닌 caddieId로 조회됨.
        caddieName: null,
        vacationStartDate: null,
        vacationEndDate: null,
        vacationStartDayOfTheWeek: null,
        vacationEndDayOfTheWeek: null,
      },
      searchOptions: {
        //gridField
        caddieName: null,
      },
      selectionSettings: {
        type: "Multiple",
        mode: "Both",
        enableToggle: false,
      },
      isModifyMode: false,
    };
  },
  computed: {
    isPopupOpened() {
      return (
          this.isCaddieSelectPopupOpen ||
          this.isEditMultipleColumnsPopupOpen
      );
    },
    vacationDateRange: {
      get: function () {
        return {
          from: this.searchConditions.vacationStartDate,
          to: this.searchConditions.vacationEndDate,
        };
      },
      set: function (vacationDateRange) {
        this.searchConditions.vacationStartDate = vacationDateRange.from;
        this.searchConditions.vacationEndDate = vacationDateRange.to;
      },
    },
    caddieInfoGridOptions() {
      return {
        provides: [Edit, ForeignKey, Resize, Selection, Page, ExcelExport],
        pageSettings: { pageSize: 50 },
        editSettings: {
          allowEditing: false,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        validationRules: {
          caddieName: {
            required: true,
          },
          caddieNo: {
            required: true,
          },
          caddieAttendDiv: {
            required: true,
          },
          vacationStartDate: {
            required: true,
            dateFormat: true,
          },
          vacationEndDate: {
            required: true,
            dateFormat: true,
            custom: {
              method: (row) => {
                return !moment(row.vacationStartDate).isAfter(
                    row.vacationEndDate
                );
              },
              message: "main.validationMessage.vacationDateMessage",
            },
          },
        },
        columns: [
          {
            field: '_rid',
            type: 'number',
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: 'bizNoA',
            headerText: '제출자 사업자등록번호',
            width: 180,
            type: 'string',
          },
          {
            field: 'bizNameA',
            headerText: '제출자 상호',
            textAlign: 'center',
            width: 120,
            type: 'string',
          },
          {
            field: 'bizNoB',
            headerText: '제출의무자 사업자등록번호',
            textAlign: 'center',
            width: 210,
            type: 'number',
          },
          {
            field: 'bizNameB',
            headerText: '제출의무자 상호',
            textAlign: 'center',
            groupCode: 'SEX_CODE',
            width: 150,
            type: 'string',
          },
          {
            field: 'prtYear',
            headerText: '귀속연도',
            textAlign: 'center',
            width: 110,
            type: 'string',
          },
          {
            field: 'prtMonth',
            headerText: '귀속월',
            textAlign: 'center',
            isNumericType: true,
            width: 100,
            type: 'string',
            visible: this.isIncomePage,
          },
          {
            field: 'juminNo',
            headerText: '주민등록번호(용역제공자)',
            textAlign: 'center',
            groupCode: 'CADDIE_WORK_DIV',
            width: 200,
            type: 'string',
          },
          {
            field: 'caddieName',
            headerText: '성명',
            textAlign: 'center',
            groupCode: 'CADDIE_EMPLOY_DIV',
            width: 80,
            type: 'string',
          },
          {
            field: 'gubunCode',
            headerText: '용역구분코드',
            textAlign: 'center',
            width: 130,
            type: 'string',
          },
          {
            field: 'startDate',
            headerText: '용역제공기간 개시일',
            textAlign: 'center',
            width: 170,
            type: 'string',

          },
          {
            field: 'endDate',
            headerText: '용역제공기간 종료일',
            textAlign: 'center',
            width: 170,
            type: 'string',

          },
          {
            field: 'workDate',
            headerText: '용역제공일수',
            textAlign: 'right',
            width: 130,
            type: 'number',
          },
          {
            field: 'workCnt',
            headerText: '용역제공횟수',
            textAlign: 'right',
            isNumericType: true,
            width: 130,
            type: 'number',

          },
          {
            field: 'workAmt',
            headerText: '용역제공대가',
            textAlign: 'right',
            isNumericType: true,
            width: 130,
            type: 'number',

          },
        ],
      };
    },
  },
  methods: {
    numberWithCommas,
    async dataLoading(){
      const taxData = await GolfErpAPI.fetchCaddieTaxSubmissionStatements({
        visitDateFrom : this.searchConditions.vacationStartDate,
        visitDateTo : this.searchConditions.vacationEndDate
      });
      console.log(taxData["caddieTaxSubmissionStatementList"]);
      this.caddieVacationList=taxData["caddieTaxSubmissionStatementList"].map((item)=>{

        return{
          bizNameA:item.bizNameA,
          bizNameB:item.bizNameB,
          bizNoA:item.bizNoA,
          bizNoB:item.bizNoB,
          caddieId:item.caddieId,
          caddieName:item.caddieName,
          caddieNo:item.caddieNo,
          endDate: item.endDate,
          gubunCode: item.gubunCode,
          juminNo: item.juminNo,
          prtMonth: item.prtMonth,
          prtYear: item.prtYear,
          startDate: item.startDate,
          workAmt: item.workAmt,
          workCnt: item.workCnt,
          workDate: item.workDate
        };


       // console.log(item);
      });


    },
    searchValueClear() {
      this.searchConditions.caddieName = null;
      this.searchConditions.caddieId = null;
    },



    async onGridHeaderClicked(column) {
      if (!column?.field) {
        return;
      }
      const gridRefs = this.$refs.caddieInfoGrid;
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
          column,
          selectedRowIndexes
      );
    },

    isValidVacationDate() {
      if (!this.searchConditions.vacationStartDate) {
        this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["시작일자"])
        );
        return false;
      }
      if (!this.searchConditions.vacationEndDate) {
        this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
        return false;
      }

      return true;
    },
    onSearchCaddieInfo() {
      this.openCaddieSelectPopup(this.searchConditions.caddieName, "textField");
    },
    async onViewCaddieInfo() {
      if (!this.isValidVacationDate()) {
        return;
      }
      if (isGridModified(this.$refs.caddieInfoGrid)) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      this.dataLoading();

    },
    onAddCaddieInfo() {
      this.$refs.caddieInfoGrid.addRecord({
        caddieId: null,
        caddieName: null,
        caddieNo: null,
        caddieAttendDiv: null,
        vacationStartDate: getFormattedDate(new Date()),
        vacationEndDate: getFormattedDate(new Date()),
        remarks: null,
      });

      const data = this.$refs.caddieInfoGrid.getBatchCurrentViewRecords();

      this.$refs.caddieInfoGrid.editCell(data.length - 1, "caddieName");
    },

    openCaddieSelectPopup(caddieName, field, rowIndex) {
      this.isCaddieSelectPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.caddieSelectPopup.showPopup(caddieName, field, rowIndex);
      });
    },
    closeCaddieSelectPopup() {
      this.isCaddieSelectPopupOpen = false;
    },
    caddieSelectPopupConfirmed(data) {
      this.isCaddieSelectPopupOpen = false;
      if (data.selectedRowData) {
        if (data.field === "gridField") {
          this.searchOptions.caddieName = data.selectedRowData.caddieName;

          this.$nextTick(() => {
            this.$refs.caddieInfoGrid.updateRow(
                data.rowIndex,
                {
                  caddieId: data.selectedRowData.caddieId,
                  caddieName: data.selectedRowData.caddieName,
                  caddieNo: data.selectedRowData.caddieNo
                }
            );
          });
        } else if (data.field === "textField") {
          this.searchConditions.caddieName = data.selectedRowData.caddieName;
          this.searchConditions.caddieId = data.selectedRowData.caddieId;
        }
      }
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.caddieInfoGrid.updateCell(
            index,
            field,
            value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },

    caddieInfoGridActionComplete() {
      this.count = numberWithCommas(
          this.$refs.caddieInfoGrid?.getGridBatchCount() || 0
      );
    },
    onCaddieInfoGridCellSaved(args) {
      const columnName = args.columnName;
      const value = args.value;
      const previousValue = args.previousValue;

      if (columnName === "caddieName" && value !== previousValue) {
        // const rowIndexes = this.$refs.caddieInfoGrid.getSelectedRowIndexes(); // Enter시 Row가 변경되므로 getSelectedRowIndexes 사용 불가.
        const rowIndex = args.cell.parentElement.rowIndex;
        const caddieName = value;
        this.openCaddieSelectPopup(
            caddieName,
            "gridField",
            //rowIndexes[0]
            rowIndex
        );
      }
    },
    onClickExcel() {
      const columns = this.$refs.caddieInfoGrid.getColumns();
      if (columns && columns.length > 0) {
        columns[0].visible = false;
      }

      this.$refs.caddieInfoGrid.excelExport({
        fileName:this.excelName
      });
    },
    onCaddieInfoGridExcelExportComplete() {
      const columns = this.$refs.caddieInfoGrid.getColumns();
      if (columns && columns.length > 0) {
        columns[0].visible = true;
      }
    },
    async onCaddieInfoGridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.caddieInfoGridOptions.columns
          ?.forEach(column => {
            if (column?.columns) {
              column.columns
                  ?.forEach(c => columns.push(c));
            } else {
              columns.push(column);
            }
          });
      const allowedEditColumns = columns
          ?.filter(item => item.allowEditing && item.field !== 'caddieName')
          ?.map(item => item.field);
      const column = columns[cellIndex - 1];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
          column,
          selectedRowIndexes
      );
    },
  },
};
</script>


<style scoped>
body .appContent .lookup-condition .field.caddyName .content .item.input {width: 90px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>